<script>


export default {
    data() {
        return {};
    },
};
</script>

<template>
    <section class="bg-account-pages vh-100 d-flex align-items-center bg-center position-relative" :style="{
        'background-image': 'url(' + require('@/assets/images/auth-bg.png') + ')',
    }">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-5">
                    <div class="bg-white shadow">
                        <div class="p-4">
                            <div class="text-center mt-3">
                                <router-link to="/">
                                    <img src="@/assets/logo.png" alt="" class="logo-dark" height="30" />
                                </router-link>
                                <p class="text-muted mt-2">Verify your email</p>
                            </div>
                            <div class="p-3">
                                <div class="alert alert-warning text-center" role="alert">
                                    Enter the varify code sent to your email
                                </div>
                                <form novalidate="" action="#" method="get" class="av-invalid">
                                    <div>
                                        <label for="code" class="form-label">Enter code</label>
                                        <input name="code" required="" placeholder="Enter code" id="code" type="code"
                                            class="form-control" value="" />
                                    </div>
                                    <div class="d-grid mt-3">
                                        <router-link to="/login">
                                            <button type="button" class="btn btn-primary btn-none btn-block">
                                                Continue
                                            </button>
                                        </router-link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end col -->
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </section>
    <!-- end section -->
</template>